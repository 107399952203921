// Table styling

// Basic tables

table thead th, table thead th a {
  color: $color-primary;
}

.table > thead > tr > th {
  color: $color-primary;
}

// fix for datatables not using boostrap 5 hover styles
table.table.dataTable.table-hover > tbody > tr:hover > td {
  color: $color-white;
  background-color: $color-primary;
}

.table.table-hover > tbody > tr:hover > td > a:not(.btn) {
  color: $color-white;
}

// Form elements inside tables

/* Note: The entire old design is a table, be specific about specifying your styles */
/* make small buttons with just a glyphicons or halflings use less space if in a table*/
table td .btn-sm,
table td .btn-group-sm .btn {
  line-height: normal;
  // height: 2rem;
  // width: 2rem;
}
/* Make icons display nicely in anchors used as buttons */
table td a.btn-sm,
table td .btn-group-sm a.btn {
  // align-items: center;
  // display: inline-flex;
}


table td .btn-sm > .glyphicons:only-child,
table td .btn-group-sm .btn > .glyphicons:only-child,
table td .btn-sm > .halflings:only-child,
table td .btn-group.btn-group-sm .btn > .halflings:only-child {
  margin: -1rem -1rem;
}

/* by default checkboxes are wrapped with 10px margin, in tables this messes up the alignment thus disable it */
table.table .checkbox {
  margin-top: 0;
}

// Data formats inside tables

td.currency::before {
  content: "€ ";
  float: left;
}
// DataTables

@media screen and (max-width: 767px) {
  /* @media screen and (max-width: 767px) has .dataTables_info and .dataTables_paginate text-align: center so we are overwriting it */
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_length {
    text-align: left !important;
  }

  div.dataTables_wrapper div.dataTables_paginate,
  div.dataTables_wrapper div.dataTables_filter ,
  div.dataTables_wrapper div.dt-buttons {
    text-align: right !important;
  }
}

/* center aligning for (Bootstrap) DataTables */
th.dt-center, td.dt-center {
  text-align: center;
}

/* right aligning for (Bootstrap) DataTables */
th.dt-right, td.dt-right {
  text-align: right;
}

// No border tables

table > thead > tr.no-border td,
table > thead > tr.no-border th,
table > tfoot > tr.no-border td,
table > tfoot > tr.no-border th,
table > tbody > tr.no-border td {
  border: none;
}

.table-valign-middle > tbody > tr > td {
  vertical-align: middle;
}

// remove top border from table headers
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

// to be able to align search for datatables to the right in pop ups
.datatable-search-text-end .dt-container .dt-search {
  text-align: right;
}
.mail-template-contents thead,
.mail-template-contents tbody,
.mail-template-contents tfoot,
.mail-template-contents tr,
.mail-template-contents td,
.mail-template-contents th {
  border-width: inherit;
}
