/** Proxima Nova **/
@font-face {
    font-family: 'proxima_nova_altbold';
    src: url('MarkSimonson-ProximaNovaAltBold-webfont.woff') format('woff'),
         url('MarkSimonson-ProximaNovaAltBold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('MarkSimonson-ProximaNovaAltRegular-webfont.woff') format('woff'),
         url('MarkSimonson-ProximaNovaAltRegular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
