.main-header {
	height: $navbar-height;
	min-width: 100%;
	position: fixed;
	width: 100%;
	z-index: $sidebar-z-index + 1;

	.nav-link {
		color: white;
	}

	.logo {
		height: 16px;
		margin-left: 2px;
	}

	// necessary to get variables that are skipped because we don't use the navbar class on the top bar
	// scss-docs-start navbar-css-vars
	--#{$prefix}navbar-padding-x: #{if($navbar-padding-x == null, 0, $navbar-padding-x)};
	--#{$prefix}navbar-padding-y: #{$navbar-padding-y};
	--#{$prefix}navbar-color: #{$navbar-light-color};
	--#{$prefix}navbar-hover-color: #{$navbar-light-hover-color};
	--#{$prefix}navbar-disabled-color: #{$navbar-light-disabled-color};
	--#{$prefix}navbar-active-color: #{$navbar-light-active-color};
	--#{$prefix}navbar-brand-padding-y: #{$navbar-brand-padding-y};
	--#{$prefix}navbar-brand-margin-end: #{$navbar-brand-margin-end};
	--#{$prefix}navbar-brand-font-size: #{$navbar-brand-font-size};
	--#{$prefix}navbar-brand-color: #{$navbar-light-brand-color};
	--#{$prefix}navbar-brand-hover-color: #{$navbar-light-brand-hover-color};
	--#{$prefix}navbar-nav-link-padding-x: #{$navbar-nav-link-padding-x};
	--#{$prefix}navbar-toggler-padding-y: #{$navbar-toggler-padding-y};
	--#{$prefix}navbar-toggler-padding-x: #{$navbar-toggler-padding-x};
	--#{$prefix}navbar-toggler-font-size: #{$navbar-toggler-font-size};
	--#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-light-toggler-icon-bg)};
	--#{$prefix}navbar-toggler-border-color: #{$navbar-light-toggler-border-color};
	--#{$prefix}navbar-toggler-border-radius: #{$navbar-toggler-border-radius};
	--#{$prefix}navbar-toggler-focus-width: #{$navbar-toggler-focus-width};
	--#{$prefix}navbar-toggler-transition: #{$navbar-toggler-transition};
	// scss-docs-end navbar-css-vars
}
