// Styling for page layouts and common page elements (e.g. menu, footer)
.main-content {
  position: absolute;
  top: $navbar-height;
}

/* Wrapper elements */
#wrapper {
  display: table;
  width: 100%;
  height: 100vh;
}

#page-content-wrapper {
  position: absolute;
  display: block;
}

//media queries
@include media-breakpoint-only(xs) {
  frame > html > body {
    padding-top: 0;
  }

  .div_item_block {
    width: 200px!important;
  }

  #wrapper {
    display: block;
    width: 100%;
  }

  #page-content-wrapper {
    position: static;
  }
}

@include media-breakpoint-up(sm) {
  body {
    padding-top: 0;
  }

  .navbar-toggler {
    display: block;
  }

  #page-content-wrapper {
    position: relative;
  }
}

.background-color-modal {
  background-color:  $modal-content-bg;
}

/* footer */
#copyright {
  padding: 12px 20px 20px 20px;
  text-align: center;
}
