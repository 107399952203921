// Glyphicons

.main-header .btn:not(.btn-sm) .glyphicons {
  font-size: 1.25rem;
}

.badge .glyphicons {
  font-size: 0.75rem;
}

.input-group-text.glyphicons-euro {
  font-size: 1rem;
}

/* make sure that if there is only a glyph icon in a button element, it does not create extra space within the button */
.btn > .glyphicons:last-child,
.btn > .halflings:last-child {
  margin-right: 0;
}

/* align glyph icon and text inside a button */
.btn > .glyphicons {
  display: inline-block;
  margin-top: -0.1rem;
  vertical-align: middle;
}

.btn-sm > .glyphicons {
  font-size: 1rem;
}

.super-buttons .glyphicons {
  display: block !important;
  font-size: 28px !important;
  padding: 6px 0 !important;
}

/* align glyph icon inside a table row */
td span.glyphicons, span.glyphicons-valign-middle {
  vertical-align: middle;
}

label.input-group-addon .glyphicons {
  padding-top: 2px;
}

.pre-heading-link .glyphicons {
  font-size: 1.125rem;
  line-height: 1.35;
  margin-right:  4px;
}

.glyphicons.glyphicon-next-to-text {
  display: inline-block;
  margin-top: -4px;
  vertical-align: middle;
}
