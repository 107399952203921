/* Animation utility */

/* Add more animations here  when needed */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}


.animate {
  animation-duration: 250ms;
  animation-fill-mode: both;

  &-twice {
    animation-iteration-count: 2;
  }
}
