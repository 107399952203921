.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #ddd;
  text-decoration: none;
  background-color: #eeeeee;
}
//This gives text in a navbar the same padding as a button, allowing us to use them next to each other.
.navbar-text {
  padding: 0.375rem 0.75rem;
}

.nav-tabs {
  border: 1px solid transparent;
}

.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item .nav-link.active:hover {
  background-color: inherit;
  border: 0;
  color: $color-primary;
  font-weight: bold;
  border-bottom: 1px solid $color-primary;
}

.nav-tabs .nav-item .nav-link:hover {
  border: 0;
  background-color: inherit;
  color: $color-primary;
  font-weight: bold;
}

.nav-tabs .nav-item .nav-link {
  color: #000;
  border: 0;
}
