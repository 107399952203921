// Form layouts and input elements

/* allows for disabling of resizing for textareas */
.no-resize {
  resize: none;
}

.vresize {
  resize: vertical;
}

.hresize {
  resize: horizontal;
}

/* remove width 100% from form-control, to dissolve usage of form-inline or form-horizontal when using col-* > form-control */
.form-control-inline {
  min-width: 0;
  width: auto;
  max-width: 100%; /* to not allow elements to pass col-* constraint */
  display: inline;
}

/* when using col-* > label, make sure it'll vertically align with .form-control elements */
.label-inline {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 34px;
}

// Form validation

.auto-filled-is-valid {
  color: #3c763d;
  background-color: #dff0d8;
}

.auto-filled-is-invalid {
  color: #a94442;
  background-color: #f2dede;
}

input:invalid:not(.form-control) {
  background-color: #f2dede;
}

.input-daterange .input-group-addon:first-child {
  border-right: 0;
  border-left-width: 1px;
}

/* adds asterisk on label of required fields (the 'required' class is added by Symfony forms automatically) */
label.required:after {
  content: "*";
  color: $danger;
}

input[inputmode=numeric] {
  text-align: right;
}

.was-validated .form-control:invalid,
.was-validated .form-control:valid {
  background-position: center right calc(1.375rem);
}

// default bootstrap border color was 'not clear enough'. custom rule to make them a bit darker
$form-check-input-border:                 1px solid rgba($black, .5);

// boostrap 5 removed the gray background for readonly
.form-control[readonly] {
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
  // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
  opacity: 1;
}
