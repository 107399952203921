// Styling for the basic Bootstrap components

// Popovers

.popover {
  color: black !important;
}

.tooltip {
  pointer-events: none;
}
// Tabs

.tab-content {
  background-color: inherit;
}

a {
  text-decoration: none;
}
