@import "../fonts/proxima-nova/fonts";
@import "vendor/bootstrap/variables";
@import '~bootstrap/scss/bootstrap.scss';
@import "vendor/glyphicons/index";

/**
 Thematic overrides
 */
@import "vendor/bootstrap/theme/badges";
@import "vendor/bootstrap/theme/buttons";
@import "vendor/bootstrap/theme/card";
@import "vendor/bootstrap/theme/components";
@import "vendor/bootstrap/theme/forms";
@import "vendor/bootstrap/theme/glyphicons";
@import "vendor/bootstrap/theme/modal";
@import "vendor/bootstrap/theme/navs";
@import "vendor/bootstrap/theme/pages";
@import "vendor/bootstrap/theme/sizing";
@import "vendor/bootstrap/theme/tables";
@import "vendor/bootstrap/theme/text";
@import "vendor/bootstrap/theme/transitions";
@import "vendor/bootstrap/theme/utilities";
@import "vendor/bootstrap/theme/accordion";

@import "vendor/bootstrap/theme/sidebar";
@import "vendor/bootstrap/theme/header";

@import "vendor/bootstrap/theme/animate";

@import '../react/curious-ui/Forms/DateInput.scss';
@import '../react/Dashboard/dashboard.scss';
