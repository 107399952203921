// Responsive text-wrap
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text#{$infix}-nowrap   { white-space: nowrap !important; }
    .text#{$infix}-wrap   { white-space: normal !important; }
  }
}

label {
  font-weight: 700;
}

.form-check-label {
  font-weight: 400;
}
