// stylelint-disable declaration-no-important

/**
  Responsive width and height utilities
  The classes are named using the format {property}-{breakpoint}-{size}

  `property` is one of:
    w - for classes that set width
    h - for classes that set height

  `breakpoint` is one of: sm, md, lg, xl

  `size` is from the `$sizes` Sass map in _variables.scss.


  Example: <div class="w-100 w-sm-75 w-md-50 w-lg-25"></div>
 */


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}
