// override bootstrap 5 accordion's focus and active effects
.accordion-button:focus {
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button.collapsed:focus {
	box-shadow: none;
}

.accordion-button:not(.collapsed) {
	color: inherit;
	background-color: #fff;
}

.accordion-button {
	background-color: #fff;
}
