// Colors
$color-primary: #5821A2;
$color-white: #FFFFFF;
$color-light: #FCFAFD;
$color-grey: #333333;
$color-light-grey: #CCC;

$color-nav-item: #3C4858;

// variable for bootstrap 5
$primary: $color-primary;

// input
$input-bg: $color-white;

//placeholder color
$input-color-placeholder : rgb(153, 153, 153);

// Body
$body-bg: $color-light;

// Media queries breakpoints
//if the breakpoint of screen-sm changes, the smallScreenMediaQuery in globals.js must be adapted as well
$screen-xs: 680px;
$screen-sm: 968px;
$screen-md: 1192px;
$screen-lg: 1400px;
$screen-xl: 1800px;

// Bootstrap media query mixins settings
$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl
) !default;

// made container max-width values slightly bigger (consistent with BS3)
$container-max-widths: (
  sm: 750px,
  md: 970px,
  lg: 1170px
);

$sizes:
  (
    5: 5%,
    10: 10%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
);


// Type
$font-size-base: 0.875rem; // 0.875rem is 14px
$font-family-sans-serif: 'Proxima Nova', Arial, sans-serif;

// Tables
$table-hover-bg: $color-primary;
$table-hover-color: $color-white;
$table-bg: transparent;

// Tabs
$nav-tabs-link-active-bg: $color-white;

// Accordion
$accordion-bg: $color-white;

// Dropdown
$dropdown-bg: $color-white;

// Modal
$modal-backdrop-bg: $color-primary;
$modal-content-bg: $color-white;
$modal-lg: 900px;
$modal-md: 600px;

/* Full list at: https://developer.mozilla.org/nl/docs/Web/CSS/cursor */
$cursors: auto default pointer not-allowed move;

// List group
$list-group-bg: $color-white;

// Card
$card-bg: $color-white;
$card-cap-bg: transparent;

// Popover
$popover-bg: $color-white;

/*
 Topbar and Sidebar
 1. These values are hardcode in templates/office/agenda/populate_agenda.html.twig
 2. Used in assets/bundles/vendor/bootstrap/theme/_header.scss
 */
$navbar-light-color: $color-white;
$navbar-light-active-color: $color-white;
$navbar-height: 56px; // [1]
$sidebar-width: 62px; // [1]
$sidebar-width-expanded: 200px; // [1]
$sidebar-z-index: 1000; // [2]

// Transition utilities, timing values are in microseconds
$transition-types: all width height opacity;
$transtion-timings: 150 200 300 350 600;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $color-primary;
$breadcrumb-divider: "\\";
