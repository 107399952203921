.card-header-title {
	margin-bottom: 0;
	font-size: 16px;
}

/**
 * @extend should be use sparingly it duplicate the content
 * of the extended class to every @extend instance.
 */
.card-default {
	@extend .mb-3;
}

[data-bs-toggle=collapse] { /* clickable */
	cursor: pointer;
}

.card-header-title[data-bs-toggle=collapse]::after {
	@extend [class^="glyphicons"];
	@extend .glyphicons-chevron-down;
	float: right;
}

.card-header-title[data-bs-toggle=collapse][aria-expanded=true]::after {
	@extend .glyphicons-chevron-up;
}

.card-header {
	font-size: 16px;
	padding: 0.75rem 1rem;
}

.card-floating-shadow {
	border-radius: 5px;
	filter: drop-shadow(0px 3px 5px #CCC9CE);
	margin: 16px;
	padding: 8px;
}
