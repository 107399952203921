// Utilities: small reusable classes

/* TODO: Replace usages with `cursor-pointer` */
.clickable {
  cursor: pointer;
}

.opacity-none {
  opacity: 0 !important;
}

/*
 *	Scrolling
 */
.scroll-y {
  overflow-y: auto !important;
  scroll-behavior: smooth;
  /* also set (height|max-height) on the element */
}

.scroll-x {
  overflow-x: auto !important;
}

/* when using a scrollbar on an element other than body, its hidden when not active in safari on IOS. This always displays it */
/* note that this overrides all webkit browsers' scrollbars */
.scroll::-webkit-scrollbar,
.scroll-x::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.scroll::-webkit-scrollbar-thumb,
.scroll-x::-webkit-scrollbar-thumb,
.scroll-y::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(88, 33, 162, .45);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.scroll::-webkit-scrollbar-track,
.scroll-x::-webkit-scrollbar-track,
.scroll-y::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 4px;
}

.container-link {
  display: block;
  cursor: pointer;
  text-decoration: inherit;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    text-decoration: inherit;
  }
}

//Override default values to center glyphicons in buttons
table td .btn-group-sm .btn>.glyphicons:only-child,
table td .btn-group-sm>.btn>.glyphicons:only-child,
table td .btn-group-sm>.btn>.halflings:only-child,
table td .btn-group.btn-group-sm .btn>.halflings:only-child,
table td .btn-sm>.glyphicons:only-child,
table td .btn-sm>.halflings:only-child {
  margin: -7px -5px -5px;
}

.showCurrencySymbol::before {
  content: "€ ";
}
