a.btn.disabled,
a.btn[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: unset;
  /**
    required by php/ddca/templates/default/pages/beheer/index.tpl as it relies on event propagation;
    setting `pointer-events: none;` triggers events on the underlying elements
  */
}

.btn-outline-secondary {
  @include button-variant($color-white, $color-light-grey);
  color: $color-grey;

  /* HACK: override bootstrap .not(.disable).active */
  /* Colors are hardcoded, it's not possible to only get values from button mixin */
  &.active.disabled {
    color: #212529;
    background-color: #ececec;
    border-color: #b3b2b2;
  }
}

.btn-warning {
  color: theme-color('white');
}

.btn {
  padding: 0.375rem 0.5rem;
}

// Make btn-sm look nice again
.btn.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
}

//Usually btn-sm has the same size as I.E. input-group-sm. Due to the changes above this is not the case.
//Therefore, this class is to be used when the standard padding is required. It replaces the 'btn-sm' class.
.btn-sm-original-padding {
  @extend .btn-sm;
  padding: 0.375rem 0.5rem!important;
}
