.navbar-toggler {
	color: $color-white;
	padding-left: 1rem;
	position: relative;

	&:hover {
		color: $color-white;
	}

	.glyphicons {
		font-size: 1.25rem;
		position: absolute;
		top: -2px;
	}
}

.sidebar + main {
	width: calc(100% - #{$sidebar-width});

	.sticky-toolbar {
		left: $sidebar-width;
		position: sticky;
	}
}

.sidebar.expanded + main {
	width: calc(100% - #{$sidebar-width-expanded});
}
.sidebar.expanded + main .sticky-toolbar {
	left: $sidebar-width-expanded;
}

.sidebar {
	position: sticky;
	left: 0;
	top: $navbar-height;
	min-width: $sidebar-width;
	width: $sidebar-width;
	z-index: $sidebar-z-index;

	.list-group-root {
		background-color: $color-light;
		border-right: 1px solid lighten($color-grey, 65%);
		height: 100vh;
		width: inherit;
		position: fixed;
		left: 0;
		top: $navbar-height;
		overflow-y: auto;
		padding-bottom: $navbar-height * 2;
	}

	.list-group-root .list-group {
		margin-left: 1.5rem;
	}

	.list-group-item {
		align-items: center;
		border-color: transparent;
		background-color: transparent;
		border-radius: 2px;
		margin: 0.25rem;
		padding: 1px 10px;
		font-size: $font-size-base;


		.glyphicons {
			color: transparentize($color-nav-item, 0.4);
			line-height: 1.35;

			&:first-child {
				font-size: 1.5rem;
			}

			&:last-child {
				font-size: 1rem;
			}
		}

		&.list-group-item-action {
			width: auto !important;
		}

		.text {
			padding-left: 14px;
			visibility: hidden;
		}

		&.active {
			background-color: transparentize($color-primary, 0.9);
			color: $color-primary;
			font-family: 'proxima_nova_altbold', Arial, sans-serif;

			.glyphicons {
				color: $color-primary;
			}
		}

		&:hover {
			background-color: transparentize($color-nav-item, 0.9);
			color: $color-nav-item;

			.glyphicons {
				color: $color-nav-item;
			}
		}
	}

	.list-group-submenu .list-group-item {
		padding: 5px 10px;
	}

	.list-group-root .list-group .list-group-item-action.active {
		background-color: transparent;
		font-family: 'proxima_nova_altbold', Arial, sans-serif;
	}

	&:not(.expanded) {
		// Overide bootstrap collapse
		// Otherwise a large empty space is shown on a collapsed menu
		.show {
			display: none;
		}

		.text + .glyphicons {
			display: none;
		}
	}

	&.expanded {
		min-width: $sidebar-width-expanded;
		width: $sidebar-width-expanded;
		z-index: $sidebar-z-index;

		.list-group-root {
			width: $sidebar-width-expanded;
		}

		.list-group-item .text {
			visibility: visible;
		}
	}

	&:hover:not(.expanded) {
		z-index: $sidebar-z-index;

		.show {
			display: block !important;
		}

		.text + .glyphicons {
			display: inline-block;
		}

		.list-group-root {
			box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.25);
			width: $sidebar-width-expanded;
		}

		.list-group-item .text {
			visibility: visible;
		}
	}

	.navigation-overlay {
		display: none;
	}
}

.has-touch {
	.sidebar {
		bottom: 0;
		display: none;
		height: 100% !important;
		min-width: 0;
		position: fixed;
		touch-action: none;
		width: 0;

		.list-group-root {
			transition: all 350ms ease;
		}

		&.expanded {
			display: block;

			.list-group-root {
				box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.25);
				height: inherit;
				position: fixed;
				width: $sidebar-width-expanded;
			}
		}
	}

	.sidebar + main {
		.sticky-toolbar {
			left: 0;
			position: sticky;
		}
	}

	.navigation-overlay {
		background-color: transparentize($color-primary, 0.75);
		display: block;
		height: 100%;
		position: fixed;
		width: 100%;
		z-index: -1;
	}
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.main-header {
		z-index: $sidebar-z-index + 2 !important;
	}

	.list-group-root {
		z-index: $sidebar-z-index + 1;
	}
}
