// make modal backdrop use full screen, BS4 uses viewport height/width which doesn't work correctly on mobile devices when zooming
.modal-backdrop {
  right: 0;
  bottom: 0;
  width: unset; // default is 100vw
  height: unset; // default is 100vh
}

body .modal-open{
  position: fixed;
  overflow: hidden;
}

.modal {
  -webkit-overflow-scrolling: touch;
}
